import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "initFirebase";

import styled from "styled-components";
import { FormGroup, InputGroup, Input, Button } from "Components/FormItems";

const Signup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // paddingTop: "64px",
        height: "100vh",
        marginTop: "-120px",
      }}
    >
      <FormGroup>
        <p>Sign Up</p>
        <InputGroup>
          <label htmlFor="email-address">Email address</label>
          <Input
            type="email"
            label="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email address"
          />
        </InputGroup>

        <InputGroup>
          <label htmlFor="password">Password</label>
          <Input
            type="password"
            label="Create password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </InputGroup>

        <Button type="submit" onClick={onSubmit} style={{ marginTop: "16px" }}>
          Sign up
        </Button>
      </FormGroup>
    </div>
  );
};

export default Signup;
