import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from 'initFirebase';
import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate('/');
        console.log('Signed out successfully');
      })
      .catch((error) => {
        alert('an error occured, please try again');
      });
  }, []);

  return <div>Logging you out...</div>;
};
