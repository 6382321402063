import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import firebaseApp, { auth } from 'initFirebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { Input, InputGroup, FormGroup, Button } from 'Components/FormItems';
import { getDatabase, ref, child, get } from 'firebase/database';
import styled from 'styled-components';

const db = getDatabase(firebaseApp);

const Page = styled.div`
  padding: 32px 48px;
  height: 100%;
  min-height: 100vh;
  h1 {
    font-size: 32px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0;
  }
  li {
    border: 1px solid #ccc;
    // border: 1px solid #2a317d;
    border: 1px solid #a4a4bc;
    border-radius: 3px;
    padding: 16px;
    // background: #fff;
    // background: #f7f7f7;
    margin-right: 16px;
    margin-top: 16px;
    width: 24%;
    div {
      margin-bottom: 4px;
      // background: #fff;
      // background: #f7f7f7;
    }
    // b {
    //   color: #cf2e2e;
    // }
  }
  .comments {
    white-space: pre-wrap;
  }
`;

export default () => {
  const navigate = useNavigate();
  const [orders, setOrders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // user is signed in and designated auth user,
      if (user && user.email === 'audrey.claudel@gmail.com') {
        // load page
      } else {
        navigate('/');
        // User is signed out
      }
    });
  }, []);

  React.useEffect(() => {
    const dbRef = ref(db);
    get(child(dbRef, `orders`))
      .then((snapshot) => {
        setIsLoading(false);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setOrders(
            Object.keys(data).map((key) => {
              return { id: key, ...data[key] };
            })
          );
          // setOrders(Object.values(snapshot.val()));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('could not get orders', error);
      });
  }, []);

  if (isLoading) return <div>Loading</div>;
  return (
    <Page>
      <h1>User Orders</h1>
      <ul>
        {orders.map((order) => {
          return (
            <li>
              <div>
                <b>Name</b>: {order.firstName} {order.lastName}
              </div>
              <div>
                <b>Email</b>: {order.email}
              </div>
              <div>
                <b>Comments</b>
              </div>
              <div className="comments">{order.comments}</div>
            </li>
          );
        })}
      </ul>
    </Page>
  );
};
