// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import 'firebase/firestore';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBKo1enCAn6QaG3I3QMzojotB6VFwbs1lI',
  authDomain: 'wrestling-without-an-audience.firebaseapp.com',
  projectId: 'wrestling-without-an-audience',
  storageBucket: 'wrestling-without-an-audience.appspot.com',
  messagingSenderId: '582824834363',
  appId: '1:582824834363:web:65e589c68c5718ef61731e',
  measurementId: 'G-KLDB4WKK6P',
  // databaseURL: 'https://DATABASE_NAME.firebaseio.com',
  databaseURL:
    'https://wrestling-without-an-audience-default-rtdb.firebaseio.com/',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
