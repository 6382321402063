import './App.css';
import Main from 'Pages/Main';
import Signup from 'Pages/Auth/Signup';
import Login from 'Pages/Auth/Login';
import Signout from 'Pages/Auth/Signout';
import Orders from 'Pages/Orders';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  // return <Main />;
  return (
    <Router>
      <div>
        <section>
          <Routes>
            {' '}
            <Route path="/" element={<Main />} />
            <Route path="/secret/admin/signup" element={<Signup />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/signout" element={<Signout />} />
            <Route path="/admin/orders" element={<Orders />} />
          </Routes>
        </section>
      </div>
    </Router>
  );
}

export default App;
