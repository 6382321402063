import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from 'initFirebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { Input, InputGroup, FormGroup, Button } from 'Components/FormItems';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        debugger;
        navigate('/');
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  // React.useEffect(() => {
  //   const uid = 'PnygewkN3Se2ULtOwYvz2cKhcKf1';
  //   getAuth()
  //     .getUser(uid)
  //     .then((userRecord) => {
  //       // See the UserRecord reference doc for the contents of userRecord.
  //       console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
  //     })
  //     .catch((error) => {
  //       console.log('Error fetching user data:', error);
  //     });
  // }, []);
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingTop: "64px",
        height: '100vh',
        marginTop: '-120px',
      }}
    >
      <FormGroup>
        <p>Sign In</p>
        <InputGroup>
          <label htmlFor="email-address">Email address</label>
          <Input
            type="email"
            label="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email address"
          />
        </InputGroup>

        <InputGroup>
          <label htmlFor="password">Password</label>
          <Input
            type="password"
            label="Create password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </InputGroup>

        <Button type="submit" onClick={onLogin} style={{ marginTop: '16px' }}>
          Sign In
        </Button>
      </FormGroup>
    </div>
  );
};

export default Login;
