import styled from 'styled-components';

export const Button = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  color: #fff;
  background: #2a317d;
  background: #cf2e2e;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background: #cf2e2e;
    background: #2a317d;

    box-shadow: 0 0 6px rgba(33, 33, 33, 0.2);
  }
  transition: 0.3s;
`;

export const FormGroup = styled.form`
  padding: 16px;
`;

// const borderColor = '#ebedee';
// const borderColor = '#ccc';
const borderColor = '#A4A4BC';
export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid ${borderColor};
  padding: 4px;
  // background: #fff;
  color: #2a317d;

  width: 100%;
  &.has-error {
    border: 1px solid #d70040;
  }
  @media (min-width: 750px) {
    // if dont' have media
    width: 220px;
  }
  @media (min-width: 1100px) {
    // width: 400px;
    width: 100%;
  }
`;
export const TextArea = styled.textarea`
  border-radius: 4px;
  border: 1px solid ${borderColor};
  padding: 4px;
  min-height: 140px;
  min-width: 220px;
  color: #2a317d;
  width: 100%;
  // background: #fff;
  @media (min-width: 750px) {
    min-height: 200px;
    min-width: 482px;
    width: 482px;
  }
`;

export const InputGroup = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  label {
    width: 100%;
    display: block;
    margin-bottom: 4px;
  }
`;
