import React from 'react';
import BridgeImg from 'assets/argostoli_bridge.jpg';
import TheaterImg from 'assets/lesbos_theater_2.jpg';
// import MoviePoster from 'assets/movie_poster_3.png';
import MoviePoster from 'assets/movie_poster_no_title.png';
// import ArgostoliPoster from 'assets/argostoli_poster_1.png';
// import ArgostoliPoster from 'assets/argostoli_poster_2.png';
import ArgostoliPoster from 'assets/argostoli_poster_4.png';
import styled from 'styled-components';
import { Button, Input, InputGroup, TextArea } from 'Components/FormItems';
// import { getDatabase } from 'firebase/database';
import firebaseApp from 'initFirebase';
import { getDatabase, ref, set, push, child, update } from 'firebase/database';

// firebase.initializeApp(config);

// const database = getDatabase();

const FirstPageContainer = styled.div`
  // background: #f7fafc;
  // height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 500px;
  overflow: hidden;
  background: url(${TheaterImg});
  background: url(${BridgeImg});
  // background: url(${MoviePoster});
  background-size: cover;
  background-position: center;
  // background-position: 0 78%;
  background-attachment: fixed;

  // @media (min-width: 810px) {
  @media (min-width: 750px) {
    height: 100vh;
    min-height: 500px;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  top: 37%;
  right: 15%;
  right: 10%;
`;

const SecondPage = styled.div`
  // height: calc(100vh - 32px);
  min-height: 100vh;
  background: #eee;
  background: #fff3e3;

  width: 100%;
  z-index: 10;
  position: relative;
  padding: 24px;
  max-height: 100%;

  .heading {
    @media (min-width: 1100px) {
      padding-bottom: 8px;
    }
  }

  display: flex;
  justify-content: space-around;
  justify-content: center;
`;

const LeftContainer = styled.div`
  display: none;
  img {
    height: 420px;
    width: auto;
  }

  @media (min-width: 1100px) {
    display: block;
    margin-right: 40px;
  }
`;

const Header = styled.div`
  font-size: 28px;
  // padding-top: 40px;
  padding-bottom: 8px;
  padding-bottom: 16px;
  padding-top: 24px;
  @media (min-width: 750px) {
    padding-top: 40px;
  }
  @media (min-width: 1100px) {
    padding-top: 40px;
  }
`;
const SubHeader = styled.div`
  font-size: 12px;
  text-align: right;
  // padding-bottom: 48px;
  // padding-bottom: 40px;
  padding-bottom: 8px;
  @media (min-width: 1100px) {
    padding-bottom: 24px;
  }
`;

const Container = styled.div`
  // margin: auto;
  width: 100%;
  @media (min-width: 750px) {
    width: 482px;
  }
  @media (min-width: 1100px) {
    margin-left: 40px;
  }
`;

const FormGroup = styled.div`
  .heading {
  }
  button {
    margin-top: 16px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 100%;
  }

  @media (min-width: 750px) {
    & > div {
      width: 45%;
    }
  }
  @media (min-width: 1100px) {
    & > div {
      width: 100%;
    }
  }
`;

const Quote = styled.div`
  font-size: 20px;
  padding-top: 16px;
  &:last-child {
    padding-bottom: 24px;
  }
  @media (min-width: 1100px) {
    width: 482px;
  }
`;

const SuccessMessage = styled.div`
  h3 {
    font-size: 20px;
    padding-bottom: 16px;
  }
`;

const MobileQuotes = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
`;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default () => {
  const [order, setOrder] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    comments: '',
  });
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [hasValidated, setHasValidated] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  const [db, setDb] = React.useState(undefined);

  function updateOrder(key, value) {
    setOrder({ ...order, [key]: value });
  }

  function submitOrder() {
    const newPostKey = push(child(ref(db), 'orders')).key;
    if (!validate(order)) return;
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    updates['/orders/' + newPostKey] = order;
    update(ref(db), updates)
      .then((data) => {
        setHasSubmitted(true);
      })
      .catch((el) => {
        setError(true);
      });
  }

  React.useEffect(() => {
    if (hasValidated) {
      validate(order);
    }
  }, [order, hasValidated]);

  function validate(data) {
    setHasValidated(true);
    const newErrors = {};
    const required = ['firstName', 'lastName', 'email'];
    required.forEach((el) => {
      if (!data[el]) {
        newErrors[el] = true;
      }
      if (el === 'email') {
        if (!validateEmail(data[el])) newErrors[el] = true;
      }
    });
    setFormErrors(newErrors);
    return !Object.keys(newErrors).length;
  }

  React.useEffect(() => {
    setDb(getDatabase(firebaseApp));
  }, []);

  const Quotes = [
    'To watch [her films] is such a multiplicity of wonder I feel that I am only now awaking from the dream.',
    // 'An inspired madness... charged with clarity',
    'An overwhelming vision... charged with mystery',
    'As if I had seen it before',
  ];
  // const Quotes = [
  //   'To watch this filim is such a multiplicity of wonder that I can only gradually cope with it.',
  //   'A pure and noble vision... full of mystery',
  //   'Something Holy',
  // ];

  return (
    <div>
      {/* <FirstPageContainer>
      </FirstPageContainer> */}
      <SecondPage>
        <LeftContainer>
          <Header className="top">"Genius"</Header>
          {/* <img src={ArgostoliPoster} /> */}
          <img src={MoviePoster} />
          {Quotes.map((el) => (
            <Quote key={el.slice(0, 2)}>"{el}"</Quote>
          ))}
          {/* <Quote>
            "To watch this filim is such a multiplicity of wonder that I can
            only gradually cope with it."
          </Quote>
          <Quote>"A pure and noble vision... full of mystery"</Quote>
          <Quote>"Something Holy"</Quote> */}
        </LeftContainer>
        <Container>
          <Header>Wrestling Without an Audience</Header>
          <SubHeader>(coming soon)</SubHeader>
          {hasSubmitted ? (
            // <SuccessMessage>
            //   <h3>Thank you for signing up, {order.firstName}!</h3>
            //   We<span className="fallback-font">'</span>ll reach out soon with
            //   updates on the film.
            // </SuccessMessage>
            <SuccessMessage>
              <h3>Thank you for your interest, {order.firstName}!</h3>
              We<span className="fallback-font">'</span>ve received your request
              and will send updates as they come.
              {/* We<span className="fallback-font">'</span>ll try to get back to
              you. */}
            </SuccessMessage>
          ) : (
            <FormGroup>
              <MobileQuotes>
                {Quotes.map((el, idx) => {
                  if (idx === 0) {
                    return <Quote key={el.slice(0, 2)} className="top-quote">"{el}"</Quote>;
                  }
                  return <Quote key={el.slice(0, 2)}>"{el}"</Quote>;
                })}
              </MobileQuotes>

              {/* <div className="heading">
                We<span className="fallback-font">'</span>re working on the
                rerelease, but we're happy to answer questions or reserve a copy
              </div> */}
              <div className="heading">
                We<span className="fallback-font">'</span>re working on the
                rerelease, but to receive updates, reserve a copy or make
                inquiries, please use the form below.
              </div>
              <Row>
                <InputGroup>
                  <label htmlFor="first-name">
                    First Name<span className="fallback-font">*</span>
                  </label>
                  <Input
                    label="first-name"
                    className={formErrors.firstName ? 'has-error' : ''}
                    value={order.firstName}
                    onChange={(e) => updateOrder('firstName', e.target.value)}
                    required
                    placeholder="Éric"
                  />
                </InputGroup>
                <InputGroup>
                  <label htmlFor="last-name">
                    Last Name<span className="fallback-font">*</span>
                  </label>
                  <Input
                    label="Last Name"
                    value={order.lastName}
                    className={formErrors.lastName ? 'has-error' : ''}
                    onChange={(e) => updateOrder('lastName', e.target.value)}
                    required
                    placeholder="Rohmer"
                  />
                </InputGroup>
              </Row>
              <InputGroup>
                <label htmlFor="email-address">
                  Email<span className="fallback-font">*</span>
                </label>
                <Input
                  type="text"
                  label="Email address"
                  className={formErrors.email ? 'has-error' : ''}
                  value={order.email}
                  onChange={(e) => updateOrder('email', e.target.value)}
                  required
                  placeholder="my@email.com"
                />
              </InputGroup>
              <InputGroup>
                <label htmlFor="comments">Additional Information</label>
                <TextArea
                  placeholder="Comments, queries, poetry"
                  label="comments"
                  value={order.comments}
                  onChange={(e) => updateOrder('comments', e.target.value)}
                />
              </InputGroup>
              <Button className="fill" onClick={submitOrder}>
                Pre-order
              </Button>
            </FormGroup>
          )}
        </Container>
      </SecondPage>
    </div>
  );
};

// Genius
// To watch this film is such a multiplicity of wonder, it feels that I am only now awaking from the dream
// such a multiplicity of wonder, I am still waking from the dream
// that I am only now awaking from the dream.
// To watch this film is such a multiplicity of wonder; I am still waking from the dream.
// I am still awaking from the dream.
// that I am still awaking from the dream.
// a vision that unsettles as it elevates... full of mystery
// Something
// As if I had seen it before.
